<template>
  <v-app>
    <Header></Header>
    <v-main>
      <v-container fluid fill-height class="BackgroundStyle-2">
        <v-layout align-center justify-center>
          <v-flex xs12 sm9 md6>
            <div class="Logo1" width="100%">
              <img src="../../assets/Mww128.png" alt="My Wallet Watcher" align-center>
            </div>
            <v-card class="elevation-3">
              <v-card class="mx-auto" >

                <v-card-title class="title font-weight-regular justify-space-between">
                  <span>{{ currentTitle }}</span>

                  <v-avatar color="primary lighten-2"
                            class="subheading white--text"
                            size="24"
                            v-text="stepper"></v-avatar>
                </v-card-title>
                <v-divider></v-divider>
                <v-window v-model="stepper" touchless>
                  <v-window-item :value="1">
                    <v-card-text>

                      <v-text-field label="Username"
                                    v-model="user.userName"
                                    :error-messages="userNameErrors"
                                    @input="$v.user.userName.$touch()"
                                    @blur="$v.user.userName.$touch()"></v-text-field>

                      <v-text-field label="Email"
                                    v-model="user.email"
                                    :error-messages="emailErrors"
                                    @input="$v.user.email.$touch()"
                                    @blur="$v.user.email.$touch()"></v-text-field>

                      <v-text-field label="Confirm Email"
                                    v-model="user.cEmail"
                                    :error-messages="cEmailErrors"
                                    @input="$v.user.cEmail.$touch()"
                                    @blur="$v.user.cEmail.$touch()"></v-text-field>

                      <v-autocomplete :items="countries"
                                      v-model="user.countryName"
                                      label="Country"
                                      :error-messages="countryNameErrors"
                                      @input="$v.user.countryName.$touch()"
                                      @blur="$v.user.countryName.$touch()"></v-autocomplete>


                      <v-text-field label="GCT" type="number" v-model="user.gctPercentage"
                                    :error-messages="gctPercentageErrors"
                                    @input="$v.user.gctPercentage.$touch()"
                                    @blur="$v.user.gctPercentage.$touch()"></v-text-field>

                      <v-checkbox label="Join Newsletter" v-model="user.newsletter"></v-checkbox>
                    </v-card-text>
                  </v-window-item>

                  <v-window-item :value="2">
                      <v-card-text>
                          <v-autocomplete :items="currencies"
                                          v-model="user.currencyName"
                                          label="Currency Name"
                                          item-text="currencyName"
                                          item-value="currencyName"
                                          chips
                                          :error-messages="currencyNameErrors"
                                          @input="$v.user.currencyName.$touch()"
                                          @blur="$v.user.currencyName.$touch()">
                              <template slot="selection" slot-scope="data">
                                  <v-chip @input="data.parent.selectItem(data.item)"
                                          :input-value="data.selected"
                                          class="chip--select-multi"
                                          :key="JSON.stringify(data.item)">

                                      {{ data.item.currencyName }}
                                  </v-chip>
                              </template>
                              <template slot="item" slot-scope="data">
                                  <v-list-item-content>
                                      <v-list-item-title v-text="data.item.currencyName"></v-list-item-title>
                                      <v-list-item-subtitle v-text="data.item.currencyShort"></v-list-item-subtitle>
                                      <v-divider></v-divider>
                                  </v-list-item-content>
                              </template>
                          </v-autocomplete>

                          <v-text-field label="Currency Short" v-mask="'AAA'" placeholder="-- -- --"
                                        v-model="user.currencyShort"
                                        :error-messages="currencyShortErrors"
                                        @input="$v.user.currencyShort.$touch()"
                                        @blur="$v.user.currencyShort.$touch()"></v-text-field>
                      </v-card-text>
                  </v-window-item>

                  <v-window-item :value="3">
                    <v-card-text>
                      <v-text-field v-model="user.password"
                                    label="Password"
                                    :append-icon="passwordShow ? 'visibility_off' : 'visibility'"
                                    :type="passwordShow ? 'text' : 'password'"
                                    :rules="[rules.strength]"
                                    :error-messages="passwordErrors"
                                    @input="$v.user.password.$touch()"
                                    @blur="$v.user.password.$touch()"
                                    @click:append="passwordShow = !passwordShow"></v-text-field>

                      <v-text-field v-model="user.cpassword"
                                    label="Confirm Password"
                                    :append-icon="cpasswordShow ? 'visibility_off' : 'visibility'"
                                    :type="cpasswordShow ? 'text' : 'password'"
                                    :error-messages="cPasswordErrors"
                                    @input="$v.user.cpassword.$touch()"
                                    @blur="$v.user.cpassword.$touch()"
                                    @click:append="cpasswordShow = !cpasswordShow"></v-text-field>
                    </v-card-text>
                  </v-window-item>

                </v-window>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-btn color="primary" :disabled="stepper === 1" rounded @click.native="stepper--">Back</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn v-if="stepper !== 3"
                         color="primary"
                         rounded
                         @click.native="moveToNext($v)">
                    Next
                  </v-btn>
                  <v-btn v-if="stepper === 3" color="primary" rounded class="right" @click.native="onComplete($v.user)">Finish</v-btn>
                </v-card-actions>
              </v-card>



              </v-card>
              <v-card-text>
                <v-divider light> </v-divider>
                <v-btn color="accent" block rounded v-on:click.prevent="$router.push({ path: '/Login' })">
                  Login
                </v-btn>
              </v-card-text>
</v-flex>
        </v-layout>
      </v-container>
    </v-main>

  </v-app>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required, sameAs, email, minLength } from 'vuelidate/lib/validators'
  import { passwordFunctions } from '../../javascript/passwordChecker'

  import { HTTP } from '../../api/httpCommon';
  import { mask } from 'vue-the-mask'

    import header from '../../components/header.vue'

    import currencyJson from '../../json/currencies.json'
    import countryJson from '../../json/countries.json'

  export default {
    mixins: [validationMixin],
    directives: { mask },
    components: {
      'Header': header
    },
    /// Added vm to create a Component Instance that can be called later
    data( vm ) {
      return {
        showErrorMessage: false,
        errorMessage: '',
        passwordShow: false,
        cpasswordShow:false,
        stepper: 1,
        user: {
          userName: '',
          email: '',
          cEmail: '',
          password: '',
          cpassword: '',
          subscriptionType: 'Free',
          //PaymentDay: 1,
          gctPercentage: 0,
          //GCTDate: '',
          countryName: '',
          newsletter: false,
          //AddExtraToBudget: false,
          //ExtraPercentage: 0,
          currencyName: '',
          currencyShort: '',
          addExcessToSavingsAccount: false
        },
        registerUser: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            subscriptionType: 'Free',
            //PaymentDay: 1,
            gctPercentage: 0,
            //GCTDate: '',
            countryName: '',
            newsletter: false,
            //AddExtraToBudget: false,
            //ExtraPercentage: 0,
            currencyName: '',
            currencyShort: '',
            addExcessToSavingsAccount: false
        },
        currency: {
          id: 0,
          currencyName: '',
          currencyShort: ''
        },
        currencies: [],
        rules: {
          strength: vm.getPasswordStrength
        },
        countries: [],
        subscriptionTypes: [
          { name: "Free", price: 0 },
          { name: "Single", price: 0 },
          { name: "Couple", price: 0 }
        ],
        count: 0
      }
    },
    computed: {
      userNameErrors() {
        const errors = []
        if (!this.$v.user.userName.$dirty) return errors
        !this.$v.user.userName.required && errors.push('Username is required')
        return errors
      },
      emailErrors() {
        const errors = []
        if (!this.$v.user.email.$dirty) return errors
        !this.$v.user.email.email && errors.push('Must be valid e-mail')
        !this.$v.user.email.required && errors.push('E-mail is required')
        return errors
      },
      cEmailErrors() {
        const errors = []
        if (!this.$v.user.cEmail.$dirty) return errors
        !this.$v.user.cEmail.email && errors.push('Must be valid e-mail')
        !this.$v.user.cEmail.required && errors.push('Confirm E-mail is required')
        !this.$v.user.cEmail.sameAsPassword && errors.push(' Emails must be identical')
        return errors
      },
      passwordErrors() {
        const errors = []
        if (!this.$v.user.password.$dirty) return errors
        !this.$v.user.password.required && errors.push('Password is required')
        return errors
      },
      cPasswordErrors() {
        const errors = []
        if (!this.$v.user.cpassword.$dirty) return errors
        !this.$v.user.cpassword.sameAsPassword && errors.push(' Passwords must be identical')
        !this.$v.user.cpassword.required && errors.push('Password is required')
        return errors
      },
      countryNameErrors() {
        const errors = []
        if (!this.$v.user.countryName.$dirty) return errors
        !this.$v.user.countryName.required && errors.push('Country is required')
        return errors
      },
      gctPercentageErrors() {
        const errors = []
        if (!this.$v.user.gctPercentage.$dirty) return errors
        !this.$v.user.gctPercentage.required && errors.push('GCT is required')
        return errors
      },
      currencyNameErrors() {
        const errors = []
        if (!this.$v.user.currencyName.$dirty) return errors
        !this.$v.user.currencyName.required && errors.push('Currency Name is required')
        return errors
      },
      currencyShortErrors() {
        const errors = []
        if (!this.$v.user.currencyShort.$dirty) return errors
        !this.$v.user.currencyShort.required && errors.push('Currency Short is required')
        !this.$v.user.currencyShort.minLength && errors.push('Currency Short needs 3 characters')
        return errors
      },
      currentTitle() {
        switch (this.stepper) {
          case 1: return 'Personal Details'
          case 2: return 'Currency'
          default: return 'Password'
        }
      }
    },
    methods: {
      //moveToSecondStep: function ($v) {
      //  if (this.validatePersonalDetail($v)) {
      //    this.stepper = 2
      //  }
      //},
      //moveToThirdStep: function ($v) {
      //  if (this.validateCurrency($v)) {
      //    this.stepper = 3
      //  }
      //},
      //moveToFinalStep: function () {
      //  if (this.validateSubscription()) {
      //    this.stepper = 4
      //  }
      //},
      moveToNext: function ($v) {
        switch (this.stepper) {
          case 1:
            if (this.validatePersonalDetail($v.user)) {
              this.stepper ++
            }
            break;
          case 2:
            if (this.validateCurrency($v.user)) {
              this.stepper ++
            }
            break;
          case 3:
            if (this.validateSubscription()) {
              this.stepper ++
            }
            break;
        }
      },
      validateSubscription: function () {
        if (this.user.subscriptionType === '') {
          this.showErrorMessage = true
          this.errorMessage = "You Must Select A Subscription Type"
          return false
        }
        return true
      },
      validatePersonalDetail: function ($v) {
        //Triggers Error Messages
        $v.userName.$touch()
        $v.email.$touch()
        $v.cEmail.$touch()
        $v.countryName.$touch()
        $v.gctPercentage.$touch()

        if ($v.userName.$invalid || $v.email.$invalid || $v.cEmail.$invalid || $v.countryName.$invalid || $v.gctPercentage.$invalid) {
          return false
        }
        return true

      },
      validateCurrency: function ($v) {
        //Triggers Error Messages
        $v.currencyName.$touch()
        $v.currencyShort.$touch()

        if ($v.currencyName.$invalid || $v.currencyShort.$invalid) {
          return false
        }

        //this.user.currency = this.user.currencyShort

        return true

      },
      validatePassword: function ($v) {
        //Triggers Error Messages
        $v.password.$touch()
        $v.cpassword.$touch()

        if ($v.password.$invalid || $v.cpassword.$invalid) {
          return false
        }
        return true
      },
      register: function () {
          HTTP.post('AuthManagement/Register',
            this.registerUser
        )
          .then(response => {

            if (response.status === 200) {
              this.$router.push({ path: '/login' })
            }

          })
          .catch(e => {
            console.log(e)
          })
      },
      subscriptionSelected: function (itemName) {
        this.user.subscriptionType = itemName;
        this.showErrorMessage = false
      },
      //currencyHandler() {
      //  for (let i = 0; i < this.currencies.length; i++) {
      //    if (this.currencies[i].currencyName.toLower().indexOf(this.user.currencyName.toLower()) === -1) {
      //      this.user.currencyShort = this.currencies[i].currencyShort
      //      return
      //    }
      //  }

      //  this.addCurrency()
      //},
      addCurrency: function ($v) {
        this.currency.currencyName = this.user.currencyName
        this.currency.currencyShort = this.user.currencyShort

        let newEntry = JSON.parse(JSON.stringify(this.currency))

        this.addDialog = false

        newEntry.id = this.getLargestIdFromCurrencies() + 1

        this.$store.commit('addNewCurrencyToStore', newEntry)
      },
      getLargestIdFromCurrencies: function () {
        let largest = 0
        for (let i = 0; i < this.currencies.length; i++) {
          if (this.currencies[i].id > largest) {
            largest = this.currencies[i].id
          }
        }
        return largest
        },
        prepForApi: function () {
            let name = this.user.userName.split(" ")

            this.registerUser.firstName = name[0]
            this.registerUser.lastName = name[1]
            this.registerUser.email = this.user.email
            this.registerUser.password = this.user.password
            this.registerUser.subscriptionType = this.user.subscriptionType
            this.registerUser.gctPercentage = this.user.gctPercentage
            this.registerUser.countryName = this.user.countryName
            this.registerUser.newsletter = this.user.newsletter
            this.registerUser.currencyName = this.user.currencyName
            this.registerUser.currencyShort = this.user.currencyShort
            this.registerUser.addExcessToSavingsAccount = this.user.addExcessToSavingsAccount
        },
      onComplete: function ($v) {
        //this.currencyHandler()
          this.addCurrency()

        if (this.validatePersonalDetail($v) && this.validateSubscription() && this.validatePassword($v)) {
            this.prepForApi()
            this.register()
        }
      },
      getPasswordStrength: function () {
        return passwordFunctions.isPasswordStrength(this.user.password)
        },
        returnSelectedCurrency: function (currency) {
            return currency.currencyName === this.user.currencyName
        }
        },
    watch: {
        "user.currencyName": function () {
            this.user.currencyShort =
                this.currencies.find(this.returnSelectedCurrency).currencyShort
        }
    },
    beforeMount() {
        this.currencies = currencyJson
        this.countries = countryJson
    },
    validations: {
      user: {
        userName: {
          required
        },
        email: {
          required,
          email
        },
        cEmail: {
          email,
          required,
          sameAsPassword: sameAs('email')
        },
        password: {
          required
        },
        cpassword: {
          required,
          sameAsPassword: sameAs('password')
        },
        countryName: {
          required
        },
        gctPercentage: {
          required
        },
        currencyName: {
            required
        },
        currencyShort: {
            required,
            minLength: minLength(3)
        }
      }
    }

  }
</script>
